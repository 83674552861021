import formatPhoneNumber from 'utils/phone/formatPhoneNumber';

export function getProspectPayload(values, previousValues) {
  const {
    name,
    cpf,
    email,
    emailCollision,
    phone,
  } = {
    ...previousValues,
    ...values,
  };
  return {
    name,
    cpf,
    email: emailCollision || email,
    ...(phone !== undefined && { phone: formatPhoneNumber(phone) }),
  };
}
