import React, { useContext } from 'react';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Link from '@nubank/nuds-web/components/Link/Link';
import Button from '@nubank/nuds-web/components/Button/Button';
import Checkbox from '@nubank/nuds-web/components/Checkbox/Checkbox';
import Box from '@nubank/nuds-web/components/Box/Box';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import { PropTypes } from '@nubank/nuds-web/index-0e40e0c2';
import { useBreakpointsMediaUp } from '@nubank/nuds-web/styles/breakpoints';

import {
  getFieldProperties,
  normalizeFieldValidations,
} from 'patterns/ShortForm/utils';
import { ERROR_MESSAGES } from 'patterns/ShortForm/constants';
import { getProspectType } from 'utils/prospectTypes';
import useMobileOS from '@nubank/www-latam-commons/utils/hooks/useMobileOS';
import { trackRTRAppStoreClick } from 'tracking/application/rtr';
import generateDPRsForCMSImage from '@nubank/www-latam-commons/utils/cms/images/generateDPRsForCMSImage';

import { EmailCollisionIcon } from './components/EmailCollisionIcon';

const EMAIL_COLLISION_FIELDS_ORDER = ['emailCollision', 'emailCollisionConfirmation'];

const backgroundImage = (generateDPRsForCMSImage([{
  imageDesktop: {
    url: 'https://nubank.com.br/images-cms/1679939895-five_hands_holding_cards.jpg',
  },
}]));

function EmailCollisionForm({
  email,
  isDisabled,
  isSubmitting,
  prospectTypeKey,
  hasLoginButton,
  createTextFieldTracker,
  createCheckboxTracker,
}) {
  const mobileSystem = useMobileOS();
  const isDesktop = useBreakpointsMediaUp('lg');

  const { formatMessage } = useContext(NuDsContext);

  const prospectType = getProspectType(prospectTypeKey);

  const handleAppStoreClick = store => () => {
    trackRTRAppStoreClick({
      prospectType: prospectType.type,
      store,
    });
  };

  return (
    <Box
      display="grid"
      gridTemplateColumns={{
        lg: '475px 1fr',
      }}
      minHeight="100vh"
    >
      <Box
        backgroundSize="cover"
        backgroundImage={backgroundImage}
      />
      <Box
        paddingHorizontal={{
          xs: '6x',
          md: '16x',
          lg: '16x',
        }}
        paddingVertical={{
          xs: '16x',
          lg: '24x',
        }}
      >
        <Box marginBottom="8x">
          <EmailCollisionIcon
            aria-hidden
          />
        </Box>
        <Box
          borderBottom="3px solid #EFEFEF"
          paddingBottom={{
            xs: '8x',
            lg: '12x',
          }}
          marginBottom={{
            xs: '8x',
            lg: '12x',
          }}
        >
          <Typography
            color="black"
            colorVariant="default"
            variant="heading2"
            intlKey="ARCHIVED.ACCESS_SCREEN_TITLE"
            intlValues={{
              email: (
                <Typography
                  tag="span"
                  color="primary"
                  variant="heading2"
                  wordBreak="break-all"
                  textOverflow="clip"
                >
                  {email}
                </Typography>
              ),
            }}
          />
        </Box>

        <Box
          marginBottom={{
            xs: '16x',
            lg: '8x',
          }}
        >
          <Typography
            variant="heading4"
            intlKey="ARCHIVED.NOT_ACCESS_EMAIL"
          />

        </Box>

        <Box
          display="grid"
          marginBottom="16x"
          gridColumnGap="8x"
          gridTemplateColumns={{ lg: '1fr 1fr' }}
        >
          {EMAIL_COLLISION_FIELDS_ORDER.map(fieldName => {
            const fieldProps = getFieldProperties(fieldName);
            const fieldTrackingFn = createTextFieldTracker(fieldName);

            return (
              <TextField
                key={fieldName}
                id={`field-${fieldName}`}
                name={fieldName}
                autoComplete="off"
                type={fieldProps.type}
                onBlur={fieldTrackingFn}
                label={formatMessage({ id: fieldProps.label })}
                syncValidations={normalizeFieldValidations(
                  fieldProps.validations,
                  formatMessage,
                )}
              />
            );
          })}
        </Box>
        <Checkbox
          id="field-accepted"
          name="accepted"
          onChange={createCheckboxTracker('privacyNotice')}
          syncValidations={{
            required: formatMessage({ id: ERROR_MESSAGES.accepted }),
          }}
          label={(
            <Typography
              variant="subtitle1"
              tag="span"
              color="black"
              colorVariant="default"
              intlKey="COMMON.APPLICATION.LABEL.PRIVACY_POLICY"
              intlValues={{
                a: content => (
                  <Link
                    href="/contrato/politica-privacidade"
                    color="primary"
                    target="_blank"
                  >
                    {content}
                  </Link>
                ),
              }}
            />
          )}
        />

        {hasLoginButton
          ? (
            <Box
              flexDirection="column"
              display="flex"
              alignItems="center"
              height="24x"
              justifyContent="space-between"
            >
              <Button
                type="submit"
                variant="contained"
                styleVariant="primary"
                disabled={typeof window === 'undefined' || isDisabled || isSubmitting}
                intlKey="ARCHIVED.CONTINUE_NEW_EMAIL"
              />
              {!isDesktop && (
                <Box
                  display="flex"
                  alignItems="center"
                  paddingTop="6x"
                >
                  <Link
                    variant="action"
                    href={mobileSystem === 'android' ? 'https://nubank.app.link/aUvpOf6NP6' : 'https://nubank.app.link/p9NYaS0NP6'}
                    intlKey="ARCHIVED.ALREADY_CLIENT"
                    onClick={handleAppStoreClick(mobileSystem)}
                  />
                </Box>
              )}
            </Box>
          )
          : (
            <Box
              display="flex"
              justifyContent="center"
              height="24x"
            >
              <Button
                type="submit"
                variant="contained"
                styleVariant="primary"
                disabled={typeof window === 'undefined' || isDisabled || isSubmitting}
                intlKey="ARCHIVED.CONTINUE_NEW_EMAIL"
              />
            </Box>
          )}

      </Box>
    </Box>
  );
}

EmailCollisionForm.propTypes = {
  createCheckboxTracker: PropTypes.func.isRequired,
  createTextFieldTracker: PropTypes.func.isRequired,
  email: PropTypes.string.isRequired,
  hasLoginButton: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  prospectTypeKey: PropTypes.string.isRequired,
};

export default EmailCollisionForm;
