import isEmpty from '@nubank/www-latam-commons/utils/object/isEmpty';
import { fetchJson } from '@nubank/www-latam-commons/utils/http';
import { sentryException } from 'services/errorTracking/sentryException';

export function createRealTimeAnalysisRepository(fetch = fetchJson) {
  return {
    async getPollingConfig(url) {
      try {
        const { polling, template } = await fetch(url);

        if (isEmpty(polling) && isEmpty(template)) return false;

        return {
          hasTemplate: !isEmpty(template),
          template,
          retryAfter: polling?.retry_after,
          maxRetries: polling?.max_retries,
        };
      } catch (error) {
        sentryException({
          error,
          flow: 'application_flow',
          checkpoint: 'get_polling_config',
          namespace: 'createRealTimeAnalysisRepository()',
        });
        return false;
      }
    },

    async getAnalysisResult(url) {
      try {
        return await fetch(url);
      } catch (error) {
        sentryException({
          error,
          flow: 'application_flow',
          checkpoint: 'get_analysis_result',
          namespace: 'createRealTimeAnalysisRepository()',
        });
        return false;
      }
    },
  };
}

export default createRealTimeAnalysisRepository(fetchJson);
