import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import FeedbackEmail from './components/FeedbackEmail/FeedbackEmail';

const FeedbackProductApprovedMGM = dynamic(() => import('./components/FeedbackProductApproved/FeedbackProductApprovedMGM'));
const RealTimeResponseRevampVariant1 = dynamic(() => import('./components/RealTimeResponseRevampVariant1/RealTimeResponseRevampVariant1'));
const RealTimeResponseRevampVariant2 = dynamic(() => import('./components/RealTimeResponseRevampVariant2/RealTimeResponseRevampVariant2'));
const RealTimeResponseRevampVariant3 = dynamic(() => import('./components/RealTimeResponseRevampVariant3/RealTimeResponseRevampVariant3'));
const RealTimeResponseRevampVariant4 = dynamic(() => import('./components/RealTimeResponseRevampVariant4/RealTimeResponseRevampVariant4'));
const RealTimeResponseRevampVariant5 = dynamic(() => import('./components/RealTimeResponseRevampVariant5/RealTimeResponseRevampVariant5'));
const RealTimeResponseUvBundle = dynamic(() => import('./components/RealTimeResponseUvBundle/RealTimeResponseUvBundle'));

function RegistrationStatus({
  prospectEmail,
  realtimeResult,
}) {
  const { analysisResult, prospect } = realtimeResult;

  const template = analysisResult?.template;
  const hasTemplate = Boolean(template);

  if (!hasTemplate) {
    return (
      <FeedbackEmail
        email={prospectEmail}
      />
    );
  }

  const FeedbackComponent = {
    rtr_revamp_variant_v1: RealTimeResponseRevampVariant1,
    rtr_revamp_variant_v2: RealTimeResponseRevampVariant2,
    rtr_revamp_variant_v3: RealTimeResponseRevampVariant3,
    rtr_revamp_variant_v4: RealTimeResponseRevampVariant4,
    rtr_revamp_variant_v5: RealTimeResponseRevampVariant5,
    uv_bundle: RealTimeResponseUvBundle,
  }[template.template_version] || FeedbackProductApprovedMGM;

  return (
    <FeedbackComponent
      approvalTemplate={template}
      prospect={prospect}
    />
  );
}

RegistrationStatus.propTypes = {
  prospectEmail: PropTypes.string.isRequired,
  realtimeResult: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      analysisResult: PropTypes.oneOfType([
        PropTypes.shape({
          template: PropTypes.shape({
            id: PropTypes.string,
          }),
        }),
        PropTypes.bool,
      ]),
      prospect: PropTypes.shape({
        ddlLink: PropTypes.string.isRequired,
        marketingId: PropTypes.string.isRequired,
        prospectType: PropTypes.string.isRequired,
      }),
    }),
  ]).isRequired,
};

export default React.memo(RegistrationStatus);
