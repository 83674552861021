import styled, { css } from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const StyledTypography = styled(Typography)`
  word-break: break-word;
  max-width: 18.5rem;

  ${breakpointsMedia({
    md: css`
      max-width: 24.75rem;
    `,
  })}
`;

export const StyledTypographyEmail = styled(Typography)`
  hyphens: auto;
`;
