import React from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Box from '@nubank/nuds-web/components/Box/Box';
import Drawer from '@nubank/nuds-web/components/Drawer/Drawer';

import DrawerPortal from './DrawerPortal';
import CompleteFormVerticalLoading from './components/CompleteFormVertical/CompleteFormVerticalLoading';
import { useApplicationFormDrawerContext } from './ApplicationFormContext';
import { CompleteFormVerticalPropTypes } from './components/CompleteFormVertical/CompleteFormVertical';

const AsyncCompleteFormVertical = dynamic(
  import('./components/CompleteFormVertical/CompleteFormVertical'),
  {
    loading: CompleteFormVerticalLoading,
    ssr: false,
  },
);

export const ApplicationFormDrawer = ({ formProps }) => {
  const {
    initialField,
    initialFieldValue,
    isDrawerOpen,
    closeDrawer,
    initialTouched,
  } = useApplicationFormDrawerContext();
  return (
    <DrawerPortal>
      <Drawer
        width="100%"
        id="complete-form-drawer"
        direction="bottom"
        open={isDrawerOpen}
        onClose={closeDrawer}
      >
        {({ DrawerContent, DrawerCloseButton }) => (
          <DrawerContent>
            <Box position="relative">
              {formProps.closeButton && (
              <Box position="absolute" padding="4x" right="0" zIndex="2">
                <DrawerCloseButton onClick={closeDrawer} />
              </Box>
              )}
              {/* TODO: Write a test case for this */}
              {isDrawerOpen && (
                <AsyncCompleteFormVertical
                  {...formProps}
                  initialTouched={initialTouched}
                  initialField={initialField}
                  initialValues={{ [initialField]: initialFieldValue }}
                />
              )}
            </Box>
          </DrawerContent>
        )}
      </Drawer>
    </DrawerPortal>
  );
};

ApplicationFormDrawer.propTypes = {
  formProps: PropTypes.shape(CompleteFormVerticalPropTypes).isRequired,
};
