import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { NuDsContext } from '@nubank/nuds-web/components/NuDSProvider/NuDSProvider';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';

import { sendEvent } from '@nubank/www-latam-commons/services/analytics';

import StyledInput from './styles/Input';

const COPY_LINK = 'https://nuapp.nubank.com.br/bnVhcHA6Ly9iZGMvdGVsYS1xdWVudGUvZXhwci91bmRlcmFnZS1tYXJrZXRpbmctc2NyZWVuP3V0bV9zb3VyY2U9cGFyZW50X3NvZnRfbWdtJnV0bV9tZWRpdW09d2ViYiZ1dG1fbWVkaXVtPWxpbms=';
const SHARE_DOUBLE_IS_CLIENT_LINK = 'https://nuapp.nubank.com.br/bnVhcHA6Ly9iZGMvdGVsYS1xdWVudGUvZXhwci91bmRlcmFnZS1tYXJrZXRpbmctc2NyZWVuP3V0bV9zb3VyY2U9cGFyZW50X3NvZnRfbWdtJnV0bV9tZWRpdW09dHdvX2J1dHRvbiZ1dG1fbWVkaXVtPWlzX2NsaWVudA==';
const SHARE_DOUBLE_NOT_CLIENT_LINK = 'https://nuapp.nubank.com.br/bnVhcHA6Ly9iZGMvdGVsYS1xdWVudGUvZXhwci91bmRlcmFnZS1tYXJrZXRpbmctc2NyZWVuP3V0bV9zb3VyY2U9cGFyZW50X3NvZnRfbWdtJnV0bV9tZWRpdW09dHdvX2J1dHRvbiZ1dG1fbWVkaXVtPWlzX25vdF9jbGllbnQ=';

const TWO_BUTTON = 'two_button';

function UnderageSoftMgmBox({
  navigatorShare,
  trackingFunction,
  copyFunction,
  ...props
}) {
  const [hasShareSupport, setHasShareSupport] = useState(false);
  const [copyButtonText, setCopyButtonText] = useState(
    'UNDERAGE.SOFTMGM.BUTTON.COPY',
  );
  const inputField = useRef(null);

  const { formatMessage } = useContext(NuDsContext);

  useEffect(() => {
    setHasShareSupport(Boolean(navigatorShare));
  }, []);

  const handleCopyLink = () => {
    inputField.current.select();
    copyFunction();
    setCopyButtonText('UNDERAGE.SOFTMGM.BUTTON.COPIED');
    trackingFunction('UNDERAGE_SOFT_MGM_EVENTS', { action: 'copy' });
    setTimeout(() => {
      setCopyButtonText('UNDERAGE.SOFTMGM.BUTTON.COPY');
    }, 3000);
  };

  const handleShareLink = ({ buttonVariant, isCustomer, shareLink }) => {
    if (hasShareSupport) {
      navigatorShare({
        text: formatMessage({ id: 'UNDERAGE.SOFTMGM.SHARE.MESSAGE' }),
        url: shareLink,
      }).catch(() => { });
    }
    trackingFunction('UNDERAGE_SOFT_MGM_EVENTS', {
      action: 'share',
      label: buttonVariant,
      value: Number(isCustomer) || 0,
    });
  };

  return (
    <>
      <Box
        {...props}
        paddingHorizontal={{ xl: '6x' }}
        paddingVertical={{ lg: '6x' }}
        backgroundColor={{ xl: 'white.dark' }}
        borderRadius="24px"
        display="grid"
        gridTemplateColumns={{
          lg: hasShareSupport ? '1fr 1fr' : '1fr',
          xl: '1fr',
          xs: '1fr',
        }}
      >
        {hasShareSupport ? (
          <>
            <Button
              onClick={() => handleShareLink({
                buttonVariant: TWO_BUTTON,
                isCustomer: false,
                shareLink: SHARE_DOUBLE_NOT_CLIENT_LINK,
              })}
              variant="contained"
              intlKey="UNDERAGE.SOFTMGM.NOT_CUSTOMER.BUTTON.SHARE"
              extended
            />
            <Button
              onClick={() => handleShareLink({
                buttonVariant: TWO_BUTTON,
                shareLink: SHARE_DOUBLE_IS_CLIENT_LINK,
                isCustomer: true,
              })}
              variant="basic"
              styleVariant="primary"
              intlKey="UNDERAGE.SOFTMGM.IS_CUSTOMER.BUTTON.SHARE"
              extended
            />
          </>

        ) : (
          <>
            <Typography
              variant="paragraph1"
              strong
              intlKey="UNDERAGE.SOFTMGM.BOX.TITLE"
              marginBottom="6x"
              marginTop="4x"
            />
            <StyledInput ref={inputField} readOnly value={COPY_LINK} />
            <Button
              size="default"
              onClick={handleCopyLink}
              variant="contained"
              intlKey={copyButtonText}
              extended
            />
          </>
        )}
      </Box>
    </>
  );
}
UnderageSoftMgmBox.propTypes = {
  copyFunction: PropTypes.func,
  navigatorShare: PropTypes.func,
  trackingFunction: PropTypes.func,
};

// TO-DO: replace execCommand with navigator.clipboard.writeText() when Browser Support changes
/* eslint-disable no-undef */
UnderageSoftMgmBox.defaultProps = {
  copyFunction: typeof globalThis === 'object'
    ? () => globalThis?.document?.execCommand('copy')
    : () => { },
  navigatorShare: typeof globalThis === 'object' && globalThis?.navigator?.share
    ? data => (globalThis || window).navigator.share(data)
    : undefined,
  trackingFunction: sendEvent,
};
/* eslint-enable no-undef */

export default UnderageSoftMgmBox;
