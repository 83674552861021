import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import dynamic from 'next/dynamic';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import UnderageSoftMgmBox from './components/UnderageSoftMgmBox/UnderageSoftMgmBox';

const UnderageSoftMGMIcon = dynamic(
  () => import('./components/UnderageSoftMGMIcon/UnderageSoftMGMIcon'),
  { ssr: false },
);

function UnderageSoftMGM() {
  const steps = [
    {
      id: 1,
      title: 'Seu pai ou mãe precisam ser clientes Nubank',
      icon: '1',
    },
    {
      id: 2,
      title:
        'Clicar em ”Conta para seus filhos” na tela inicial do aplicativo deles',
      icon: '2',
    },
    {
      id: 3,
      title: 'Inserir seus dados e consentir a abertura de conta',
      icon: '3',
    },
  ];

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection={{
        xs: 'column',
        lg: 'row',
      }}
      justifyContent={{
        xs: 'center',
        lg: 'space-between',
      }}
      alignItems={{
        xs: 'center',
        lg: 'stretch',
      }}
      minHeight="100vh"
    >
      <Box
        order={{ lg: '1' }}
        aria-hidden="true"
        backgroundColor="white.dark"
        minHeight="100vh"
        paddingHorizontal={{
          lg: '3rem',
          xl: '4rem',
        }}
        paddingTop={{ xs: '1.5rem', md: '2.5rem' }}
        display={{ xs: 'none', lg: 'flex' }}
        justifyContent={{ lg: 'center' }}
        alignItems={{ lg: 'center' }}
      >
        <UnderageSoftMGMIcon />
      </Box>

      <Box
        width="100%"
        display={{
          xs: 'contents',
          lg: 'flex',
        }}
        order={{ lg: '2' }}
        flexDirection={{ lg: 'column' }}
        borderLeft={{ lg: '1px solid' }}
        borderLeftColor={{ lg: 'white.dark' }}
      >
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={{ md: '1', lg: '0' }}
          padding={{
            xs: '1rem 1.5rem 2.5rem',
            md: '5rem 3rem 5rem',
            lg: '3.5rem 3.5rem 2.5rem',
          }}
        >
          <Box
            maxWidth={{ md: '70rem' }}
            marginBottom={{ xs: '6x', lg: '20x' }}
          >
            <Typography
              variant="heading2"
              color="black"
              intlKey="UNDERAGE.SOFTMGM.TITLE"
            />
          </Box>

          <>
            <Box display="flex" flexDirection={{ xs: 'column', xl: 'row' }}>
              <Box>
                {' '}
                {steps.map((item, key) => (
                  <Box borderBottom="1px solid" borderBottomColor="white.dark">
                    <Typography
                      display="flex"
                      flexDirection="column"
                      marginVertical="6x"
                      key={item.id}
                    >
                      <Box>
                        <Box
                          justifyContent="center"
                          display="flex"
                          alignItems="center"
                          borderRadius="50%"
                          backgroundColor="white.dark"
                          width="12x"
                          height="12x"
                          marginRight="4x"
                        >
                          <Typography variant="caption" strong color="black">
                            {key + 1}
                          </Typography>
                        </Box>
                      </Box>
                      <Box display="flex" alignItems="center">
                        <Typography variant="subtitle2" strong>
                          {item.title}
                        </Typography>
                      </Box>
                    </Typography>
                  </Box>
                ))}
              </Box>
              <Box paddingTop={{ xs: '4x' }} paddingHorizontal={{ xl: '6x' }}>
                <UnderageSoftMgmBox
                  order={{ xs: '2' }}
                  flexBasis={{ md: '1' }}
                />
              </Box>
            </Box>
          </>
        </Box>
      </Box>
    </Box>
  );
}

export default React.memo(UnderageSoftMGM);
