import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import Drawer from '@nubank/nuds-web/components/Drawer/Drawer';
import dynamic from 'next/dynamic';

import DrawerPortal from 'patterns/ApplicationFormDrawer/DrawerPortal';
import { useApplicationFormDrawerContext } from 'patterns/ApplicationFormDrawer/ApplicationFormContext';

import CompleteFormSkeleton from './components/CompleteFormSkeleton';

const CompleteForm = dynamic(() => import('./components/CompleteForm'), {
  ssr: false,
  loading: CompleteFormSkeleton,
});

const ApplicationFormWithProductChoiceDrawer = () => {
  const {
    initialField,
    initialFieldValue,
    isDrawerOpen,
    closeDrawer,
    initialTouched,
  } = useApplicationFormDrawerContext();
  return (
    <DrawerPortal>
      <Drawer
        width="100%"
        id="complete-form-drawer"
        direction="bottom"
        open={isDrawerOpen}
        onClose={closeDrawer}
      >
        {({ DrawerContent, DrawerCloseButton }) => (
          <DrawerContent>
            <Box position="relative">
              <Box position="absolute" padding="4x" right="0" zIndex="2">
                <DrawerCloseButton onClick={closeDrawer} />
              </Box>
              {isDrawerOpen && (
                <CompleteForm
                  initialField="prospectType"
                  initialTouched={initialTouched}
                  initialValues={{ [initialField]: initialFieldValue }}
                />
              )}
            </Box>
          </DrawerContent>
        )}
      </Drawer>
    </DrawerPortal>
  );
};

export default ApplicationFormWithProductChoiceDrawer;
