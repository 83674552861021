import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';

import SkeletonForTypography from 'components/SkeletonForTypography/SkeletonForTypography';

const CompleteFormSkeleton = () => (
  <Box
    display="flex"
    flexDirection={{
      xs: 'column',
      lg: 'row',
    }}
    minHeight="100vh"
  >
    <Box
      backgroundColor="primary.default"
      flex="0 0 33vw"
      display="flex"
      flexDirection={{
        lg: 'column',
      }}
      padding={{
        xs: '6x',
        md: '12x',
        lg: '56px',
      }}
      justifyContent="space-between"
    >
      <SkeletonForTypography
        $variant="heading2"
      />
    </Box>
    <Box
      flex="1"
      padding={{
        xs: '6x',
        md: '12x',
        lg: '56px',
      }}
    >
      <SkeletonForTypography
        $variant="heading2"
        $highlightColor="white"
      />
    </Box>
  </Box>
);

export default CompleteFormSkeleton;
