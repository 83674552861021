import styled from 'styled-components';
import Button from '@nubank/nuds-web/components/Button/Button';
import TextField from '@nubank/nuds-web/components/TextField/TextField';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';

export const StyledTextField = styled(TextField)`
  ${breakpointsMedia({
    xs: 'width: 300px;',
    md: 'width: 350px;',
    lg: 'width: 440px;',
  })}
`;

export const StyledButton = styled(Button)`
  all: unset;
  border: solid 2px #290b4d;
  font-size: 25px;
  padding: 25px 35px;
  cursor: pointer;
  background-color: #290b4d;
  margin-bottom: 24px;
  color: ${nuDSColor('white', 'default')};

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    gap: 15px;
    font-weight: 400;
    margin: 0 auto;
    font-size: 25px;

    svg path {
      fill: ${nuDSColor('white', 'default')};
    }
  }

  ${breakpointsMedia({
    xs: 'width: -webkit-fill-available;',
    md: 'width: 200px;',
  })}

  &:disabled {
    border: solid 2px ${nuDSColor('black', 'defaultT20')};
    color: ${nuDSColor('black', 'defaultT20')};
    background-color: ${nuDSColor('white', 'default')};

    svg path {
      fill: ${nuDSColor('black', 'defaultT20')};
    }

    &:hover {
      color: ${nuDSColor('black', 'defaultT20')};
      background-color: ${nuDSColor('white', 'default')};

      span {
        color: ${nuDSColor('black', 'defaultT20')};
      }

      svg path {
        fill: ${nuDSColor('black', 'defaultT20')};
      }
    }
  }
`;
