import { getMarketingData } from '@nubank/www-latam-commons/utils/marketingUtils';
import { trackApplication } from 'tracking/application/registration';
import {
  trackRTRStart,
  trackRTRApproval,
  trackRTRDenial,
  trackRTREmailCollision,
} from 'tracking/application/rtr';
import { sentryException } from 'services/errorTracking/sentryException';
import { getClientId } from 'utils/ga/getClientId';
import { getTrackingFunction } from 'utils/registerProspect/getTrackingFunction';
import useMobileOS, { OS } from '@nubank/www-latam-commons/utils/hooks/useMobileOS';

import { generateBranchDDLLink, DEFAULT_ANDROID_LINK, DEFAULT_IOS_LINK } from './branchDDL';
import setReturningApplicant from './core/setReturningApplicant';
import startRealTimeAnalysis from './core/startRealTimeAnalysis';
import applicationRepository from './repository/application';
import realTimeAnalysisRepository from './repository/realTimeAnalysis';
import discoveryRepository from './repository/discovery';

export function createRegisterProspect(core, repository) {
  return async ({
    prospect,
    token,
    prospectType,
    prospectTypeTracking,
    fromInviter,
    onThrottledResponse,
    onAnalysisStart,
    onAnalysisFinish,
    onApplicationFinish,
  }) => {
    const clientId = repository.getClientId();

    const additionalParameters = {
      ...(clientId && { ga_client_id: clientId }),
    };
    const typeOfProspectType = prospectType.type;
    const prospectTypeTrackingType = prospectTypeTracking
      ? prospectTypeTracking.type
      : typeOfProspectType;

    const payload = {
      prospect,
      marketing: repository.getMarketingData({ additionalParameters }),
      'accepted-contracts': [
        {
          name: 'politica_privacidade_2020_08_10',
        },
      ],
    };

    const registerUrl = await repository.getRegistrationURL(prospectType, fromInviter);

    const {
      marketingId,
      prospectId,
      hasThrottle,
      hasRealtimeAnalysis,
      realtimeUrl,
    } = await repository.sendProspectApplication(registerUrl, payload, token);

    const userAgent = useMobileOS();
    const ddlLink = await generateBranchDDLLink(prospectId, userAgent)
      .catch(error => {
        sentryException({
          error,
          flow: 'application_flow',
          checkpoint: 'generate_branch_ddl_link',
          namespace: 'createRegisterProspect()',
        });

        return userAgent === OS.ios ? DEFAULT_IOS_LINK : DEFAULT_ANDROID_LINK;
      });

    if (hasThrottle) {
      onThrottledResponse();
      return {};
    }

    repository.trackApplication({
      prospectType: prospectTypeTrackingType,
      marketingId,
    });

    core.setReturningApplicant(typeOfProspectType);

    if (hasRealtimeAnalysis) {
      onAnalysisStart();
      repository.trackRTRStart({
        prospectType: prospectTypeTrackingType,
        marketingId,
      });

      const pollingConfig = await repository.getPollingConfig(realtimeUrl);

      if (pollingConfig) {
        let template;
        let analysisResult;

        if (pollingConfig.hasTemplate) {
          template = pollingConfig?.template;
        } else {
          analysisResult = await core.startRealTimeAnalysis({
            url: realtimeUrl,
            pollingConfig,
          });

          template = analysisResult?.template;
        }

        const trackingFn = getTrackingFunction(template, repository);

        trackingFn({
          prospectType: prospectTypeTrackingType,
          marketingId,
          approvedProducts: analysisResult?.template?.approved_products,
        });

        onAnalysisFinish({
          analysisResult: analysisResult || pollingConfig,
          prospect: {
            prospectType: typeOfProspectType,
            marketingId,
            ddlLink,
          },
        });

        return { marketingId };
      }

      onAnalysisFinish(false);

      return { marketingId };
    }

    onApplicationFinish();

    return { marketingId };
  };
}

export default createRegisterProspect(
  {
    startRealTimeAnalysis,
    setReturningApplicant,
  },
  {
    ...applicationRepository,
    ...realTimeAnalysisRepository,
    ...discoveryRepository,
    getClientId,
    getMarketingData,
    trackApplication,
    trackRTRStart,
    trackRTRApproval,
    trackRTRDenial,
    trackRTREmailCollision,
  },
);
