import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Button from '@nubank/nuds-web/components/Button/Button';
import Box from '@nubank/nuds-web/components/Box/Box';

import { sendEvent } from '@nubank/www-latam-commons/services/analytics';

import { StyledIcon } from './styles/StyledIcon';
import {
  StyledTypography,
  StyledTypographyEmail,
} from './styles/StyledTypography';

function FeedbackEmail({ email }) {
  const handleNextStep = () => { sendEvent('UNDERAGE_SOFT_MGM_EVENTS', { action: 'navigate_share_screen' }); };
  return (
    <Box
      width="100%"
      height="calc(100vh - 5rem)"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <StyledIcon color="primary" ariaHidden="true" name="check-circle" />

      <Typography
        marginBottom="4x"
        variant="heading4"
        color="black"
        colorVariant="light"
        textAlign="center"
        intlKey="COMMON.APPLICATION.FEEDBACK.EMAIL.TITLE"
      />

      <StyledTypography
        marginBottom="12x"
        variant="subtitle1"
        color="black"
        textAlign="center"
        intlKey="COMMON.APPLICATION.FEEDBACK.EMAIL.DESCRIPTION"
        intlValues={{
          email: (
            <StyledTypographyEmail
              tag="span"
              variant="subtitle1"
              color="primary"
            >
              {email}
            </StyledTypographyEmail>
          ),
        }}
      />

      <Box
        marginBottom="4x"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <StyledTypography
          paddingHorizontal="12x"
          marginBottom="4x"
          variant="subtitle1"
          color="black"
          textAlign="center"
          intlKey="COMMON.APPLICATION.FEEDBACK.EMAIL.UNDERAGE.MGM.DESCRIPTION"
          intlValues={{
            strong: (
              <StyledTypography
                strong
                tag="strong"
                intlKey="COMMON.APPLICATION.FEEDBACK.EMAIL.UNDERAGE.MGM.DESCRIPTION.VALUE"
              />
            ),
          }}
        />
        <Button
          onClick={handleNextStep}
          type="submit"
          variant="contained"
          size="default"
          styleVariant="primary"
          intlKey="COMMON.APPLICATION.FEEDBACK.EMAIL.UNDERAGE.MGM.BUTTON"
        />
      </Box>
    </Box>
  );
}

FeedbackEmail.propTypes = {
  email: PropTypes.string.isRequired,
};

export default React.memo(FeedbackEmail);
