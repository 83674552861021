import { isType } from '@nubank/nuds-web/utils/isType/isType';

import realTimeAnalysisRepository from '../repository/realTimeAnalysis';

export function createRealTimeFn({ getAnalysisResult }) {
  return ({
    url,
    pollingConfig,
    onProgress,
  }) => {
    const { retryAfter, maxRetries } = pollingConfig;
    let attempt = 0;
    let result;

    const checkCondition = async (resolve, reject) => {
      attempt += 1;

      if (isType('function', onProgress)) {
        onProgress((attempt / maxRetries) * 100);
      }

      try {
        result = await getAnalysisResult(url);

        if (!result) {
          resolve(false);
        } else if (result.template) {
          resolve(result);
        } else if (attempt < maxRetries) {
          setTimeout(checkCondition, retryAfter, resolve);
        } else {
          resolve({ status: 'timeout' });
        }
      } catch (e) {
        reject(e);
      }
    };

    return new Promise(checkCondition);
  };
}

export default createRealTimeFn(realTimeAnalysisRepository);
