import React from 'react';

export function IconArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      viewBox="0 0 29 29"
      fill="none"
      {...props}
    >
      <title>Icone de seta</title>
      <path
        d="M14.1064 4.70215L12.4488 6.35966L19.0084 12.9309H4.70203V15.282H19.0084L12.4488 21.8533L14.1064 23.5108L22.6795 14.9377C23.1385 14.4786 23.1385 13.7343 22.6795 13.2753L14.1064 4.70215Z"
        fill="#290B4D"
      />
    </svg>
  );
}
