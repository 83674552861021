import React from 'react';

export function EmailCollisionIcon(props) {
  return (
    <svg width="76" height="116" viewBox="0 0 76 116" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M49.4313 0.992162C31.3979 -2.8211 13.6747 8.65638 9.8455 26.6149C9.18713 29.6972 8.9828 32.7719 9.18713 35.7713L1.42282 41.1671C-0.960953 42.825 0.0909355 46.5479 2.98931 46.7287L12.1233 47.294C16.3687 56.5558 24.8065 63.7603 35.5752 66.0362C53.6086 69.8495 71.3318 58.372 75.161 40.4135C78.9826 22.4474 67.4648 4.80543 49.4313 0.992162Z" fill="#CACACA" />
      <path d="M56.1031 53.5049L22.5482 46.3825C20.6149 45.9726 19.3775 44.0702 19.7874 42.1369L24.4351 20.2129C24.845 18.2796 26.7474 17.0422 28.6807 17.4521L62.2357 24.5745C64.169 24.9844 65.4063 26.8868 64.9965 28.8201L60.3487 50.7441C59.9389 52.6851 58.0365 53.9147 56.1031 53.5049Z" fill="white" />
      <path fillRule="evenodd" clipRule="evenodd" d="M26.0763 17.5824C26.551 17.1522 27.2846 17.1882 27.7149 17.663L41.1008 32.4331C41.8315 33.2384 42.9886 33.5002 43.9977 33.0841C43.998 33.084 43.9975 33.0842 43.9977 33.0841L63.1991 25.1344C63.791 24.8894 64.4695 25.1705 64.7146 25.7625C64.9596 26.3544 64.6784 27.0329 64.0865 27.278L44.8834 35.2283C42.9696 36.0182 40.7708 35.522 39.3826 33.992L25.9958 19.2209C25.5656 18.7462 25.6016 18.0126 26.0763 17.5824Z" fill="#FF0000" />
      <path d="M32.8569 115.998C49.5351 115.998 63.0555 113.997 63.0555 111.528C63.0555 109.059 49.5351 107.058 32.8569 107.058C16.1786 107.058 2.6582 109.059 2.6582 111.528C2.6582 113.997 16.1786 115.998 32.8569 115.998Z" fill="black" />
      <path d="M58.9876 86.1782C58.338 85.0878 57.441 84.1057 56.3274 83.3169L55.4303 82.6905C53.1103 81.0665 50.3263 80.6411 47.8516 81.2985C47.5268 79.6745 46.8927 78.3598 46.2276 77.3467C45.578 76.2563 44.681 75.2742 43.5674 74.4854L42.6703 73.859C39.8476 71.8793 36.3367 71.6859 33.5295 73.0393L46.0343 55.531C48.7719 51.6798 47.8594 46.3438 44.0082 43.6062C40.157 40.8686 34.821 41.7811 32.0834 45.6323L17.421 66.1643L17.3823 66.1411L10.345 75.8078C2.6271 86.8123 5.2951 101.993 16.3074 109.711C25.278 115.998 37.0172 115.387 45.2378 108.984C50.2644 112.734 55.0204 107.043 57.3636 102.704C62.5604 94.5611 60.9055 89.1014 58.9876 86.1782Z" fill="url(#paint0_linear_1364_292)" />
      <path fillRule="evenodd" clipRule="evenodd" d="M54.6749 94.9705C55.2475 95.2578 55.4787 95.9549 55.1914 96.5276C52.9616 100.971 49.8962 105 46.2001 108.33C45.7241 108.759 44.9906 108.72 44.5618 108.245C44.133 107.769 44.1713 107.035 44.6473 106.606C48.1277 103.471 51.017 99.6738 53.1178 95.487C53.4051 94.9144 54.1023 94.6832 54.6749 94.9705Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M48.9276 80.6495C49.1878 81.2349 48.9241 81.9204 48.3387 82.1806C46.0784 83.1852 44.0697 84.7604 42.5281 86.7054C42.1302 87.2075 41.4006 87.2919 40.8985 86.894C40.3964 86.496 40.312 85.7664 40.7099 85.2643C42.4782 83.0334 44.7847 81.2214 47.3964 80.0606C47.9819 79.8004 48.6674 80.0641 48.9276 80.6495Z" fill="black" />
      <path fillRule="evenodd" clipRule="evenodd" d="M35.0769 72.8156C35.3371 73.4011 35.0735 74.0866 34.488 74.3468C32.2298 75.3504 30.2203 76.9326 28.6694 78.8874C28.2712 79.3893 27.5416 79.4734 27.0397 79.0752C26.5378 78.6771 26.4537 77.9474 26.8519 77.4455C28.6263 75.2089 30.932 73.3884 33.5458 72.2267C34.1312 71.9665 34.8167 72.2302 35.0769 72.8156Z" fill="black" />
      <defs>
        <linearGradient id="paint0_linear_1364_292" x1="5.93751" y1="78.0709" x2="60.8433" y2="78.0709" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EC7DFF" />
          <stop offset="0.1773" stopColor="#E86DFD" />
          <stop offset="0.5242" stopColor="#DE43F9" />
          <stop offset="1" stopColor="#CE00F2" />
        </linearGradient>
      </defs>
    </svg>
  );
}
