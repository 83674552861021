import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';

import SkeletonForTypography from 'components/SkeletonForTypography/SkeletonForTypography';

const CompleteFormVerticalLoading = () => (
  <Box
    paddingVertical="20x"
    paddingLeft={{ xs: '6x', md: '12x', lg: 56 }}
    paddingRight={{ xs: '6x', md: '12x', lg: 56 }}
  >
    <SkeletonForTypography
      $variant="heading2"
      $lines={{ xs: 6, md: 6 }}
      $highlightColor="white"
      marginBottom={{ xs: '3x', lg: '2x' }}
      maxWidth={{ xs: '80%', lg: '50%' }}
    />
  </Box>
);

export default CompleteFormVerticalLoading;
