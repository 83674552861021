export function getTrackingFunction(template, repository) {
  const {
    trackRTRApproval,
    trackRTRDenial,
    trackRTREmailCollision,
  } = repository;

  if (template?.id === 'email_collision_try_another_email') return trackRTREmailCollision;

  return {
    no_products: trackRTRDenial,
    credit_card_and_nuconta: trackRTRApproval,
    secured_card_and_nuconta: trackRTRApproval,
    ultraviolet_and_nuconta: trackRTRApproval,
    credit_card: trackRTRApproval,
    nuconta: trackRTRApproval,
    uv_bundle: trackRTRApproval,
  }[template?.approved_products] || trackRTRDenial;
}
