/* eslint-disable no-underscore-dangle */

import { fetchJson } from '@nubank/www-latam-commons/utils/http';

export function createApplicationRepository(fetch) {
  return {
    async sendProspectApplication(registrationURL, payload, token) {
      const response = await fetch(registrationURL, {
        method: 'POST',
        body: payload,
        ...(token && { headers: { 'X-recaptcha-token': token } }),
      });

      const hasThrottle = Boolean(
        response.error
        && response.error.toLowerCase
        && response.error.toLowerCase() === 'ok',
      );

      const hasRealtimeAnalysis = Boolean(
        response._links && (response._links.analysis || response._links.approved_products),
      );

      const link = hasRealtimeAnalysis ? (response._links.analysis || response._links.approved_products).href : '';

      return {
        marketingId: response.marketing_id,
        prospectId: response.prospect_id,
        hasThrottle,
        hasRealtimeAnalysis,
        realtimeUrl: link,
      };
    },
  };
}

export default createApplicationRepository(fetchJson);
