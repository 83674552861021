import styled from 'styled-components';
import { spacing } from '@nubank/nuds-web/styles/themeUtils';

const StyledInput = styled.input`
  letter-spacing: -0.01em;
  background-color: #D8E5FF;
  color: #002ABE;
  border-radius: 100px;
  font-size: 1.125rem;
  width: 100%;
  padding: ${spacing('3x', '6x')};
  margin-bottom: ${spacing('2x')};
  font-family: Graphik-Medium,Graphik-Regular,'Gotham SSm A','Gotham SSm B','Helvetica Neue',Helvetica,Arial,sans-serif;
`;

StyledInput.displayName = 'StyledInput';

export default StyledInput;
