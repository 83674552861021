import React from 'react';

import FormImages from 'patterns/ApplicationFormDrawer/components/FormImages/FormImages';
import RegistrationStatus from 'patterns/RegistrationStatus/RegistrationStatus';
import registerProspectSubmit from 'patterns/ApplicationFormDrawer/registerPropesctSubmit';
import { MULTI_PRODUCT } from 'utils/prospectTypes';
import ApplicationFormWithProductChoiceDrawer from 'patterns/ApplicationFormWithProductChoiceDrawer/ApplicationFormWithProductChoiceDrawer';
import { ApplicationFormDrawer } from 'patterns/ApplicationFormDrawer/ApplicationFormDrawer';
import ApplicationFormContextProvider from 'patterns/ApplicationFormDrawer/ApplicationFormContext';

const defaultFormProps = {
  closeButton: true,
  formTitle: 'SHORT_FORM.MULTI_PRODUCT.COMPLETE_FORM_TITLE',
  formVerticalTitle: 'SHORT_FORM.MULTI_PRODUCT.COMPLETE_FORM_VERTICAL_TITLE',
  formVerticalContent: FormImages,
  formVerticalFeedback: RegistrationStatus,
  formVerticalOnSubmit: registerProspectSubmit,
  initialField: 'cpf',
  initialTouched: { cpf: false },
  prospectTypeKey: MULTI_PRODUCT,
  fromInviter: false,
  hasPhoneReminder: true,
  variant: '',
};
/*
  Configure application form for the page.
  @param WrappedComponent Component to wrap with this hoc
  @param {CompleteFormVertical.propTypes} Props to be passed to CompleteFormVertical component
*/
const withApplicationFormDrawer = (WrappedComponent, formProps = {}) => {
  const { withProductChoice = false, ...formDrawerProps } = formProps;
  const mergedFormProps = { ...defaultFormProps, ...formDrawerProps };
  const ApplicationFormWrapper = props => (
    <ApplicationFormContextProvider
      prospectTypeKey={mergedFormProps.prospectTypeKey}
      initialField={mergedFormProps.initialField}
      drawerInitialState={mergedFormProps.drawerInitialState}
    >
      <WrappedComponent {...props} />
      {withProductChoice
        ? <ApplicationFormWithProductChoiceDrawer />
        : (
          <ApplicationFormDrawer
            formProps={mergedFormProps}
          />
        )}

    </ApplicationFormContextProvider>
  );
  ApplicationFormWrapper.getInitialProps = async ctx => {
    let pageProps = {};
    if (WrappedComponent.getInitialProps) {
      pageProps = await WrappedComponent.getInitialProps(ctx);
    }
    return {
      ...pageProps,
    };
  };
  return ApplicationFormWrapper;
};
export default withApplicationFormDrawer;
