import compose from '@nubank/nuds-web/utils/functional/compose';

import registerProspect from 'domains/prospect/registerProspect';
import { trackFormSubmission } from 'tracking/application/form';
import { trackRecaptchaSuccess, trackRecaptchaFail } from 'tracking/application/recaptcha';
import { sentryException, ERROR_SEVERITY } from 'services/errorTracking/sentryException';
import { ERROR_MESSAGES } from 'patterns/ShortForm/constants';

import { getProspectPayload } from '../../utils/registerProspect/registerProspect';
import { webApplication, webProductOffer } from '../../utils/webJourneys';

function dispatchWebJourneyEvents() {
  webApplication.finishedEvent();
  webProductOffer.start();
  webProductOffer.startedEvent();
}

/*
** TODO: If the experiment turns out to be successful, we need to create a way
** to share this behavior with the other application forms.
*/
const registerProspectSubmit = async ({
  values,
  formsValues: previousValues,
  nextStep,
  prevStep,
  goToStep,
  setFormErrorMsg,
  setSubmitting,
  fromInviter,
  prospectType: prospectTypeValue,
  prospectTypeFormValue,
  formatMessage,
  setRealtimeResult,
  captcha,
}) => {
  const prospect = getProspectPayload(values, previousValues);
  const STEP_FEEDBACK_INDEX = 2;

  const prospectType = prospectTypeFormValue || prospectTypeValue;

  trackFormSubmission({ prospectType: prospectTypeValue.type });

  const displayEmailFeedback = () => {
    setSubmitting(false);
    goToStep(STEP_FEEDBACK_INDEX);
  };

  let recaptchaToken;
  try {
    recaptchaToken = await captcha;
    trackRecaptchaSuccess({ prospectType: prospectTypeValue.type });
  } catch (e) {
    recaptchaToken = null;
    trackRecaptchaFail({ prospectType: prospectTypeValue.type });
    sentryException({
      error: e,
      flow: 'application_flow',
      checkpoint: 'recaptcha',
      namespace: 'registerProspectSubmit',
      level: ERROR_SEVERITY.CRITICAL,
    });
  }

  try {
    await registerProspect({
      prospect,
      token: recaptchaToken,
      prospectType,
      prospectTypeTracking: prospectTypeValue,
      fromInviter,
      onThrottledResponse: async () => {
        displayEmailFeedback();
        webApplication.finishedEvent();
      },
      onApplicationFinish: async () => {
        displayEmailFeedback();
        webApplication.finishedEvent();
      },
      onAnalysisStart: async () => {
        setSubmitting(false);

        const isEmailCollision = Object.keys(previousValues).length;
        if (isEmailCollision) {
          prevStep();
        } else {
          nextStep();
        }

        dispatchWebJourneyEvents();
      },
      onAnalysisFinish: analysisResult => {
        webProductOffer.renderedEvent(analysisResult);
        setRealtimeResult(analysisResult);
        goToStep(STEP_FEEDBACK_INDEX);
      },
    });
  } catch (error) {
    setSubmitting(false);

    compose(
      setFormErrorMsg,
      formatMessage,
    )({ id: ERROR_MESSAGES.generic });

    sentryException({
      error,
      flow: 'application_flow',
      checkpoint: 'register_prospect',
      namespace: 'registerProspectSubmit',
      level: ERROR_SEVERITY.CRITICAL,
    });
  }
};

export default registerProspectSubmit;
