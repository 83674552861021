export const DEFAULT_ANDROID_LINK = 'https://play.google.com/store/apps/details?id=com.nu.production';

export const DEFAULT_IOS_LINK = 'https://apps.apple.com/br/app/nubank-conta-e-cart%C3%A3o/id814456780';

export function generateBranchDDLLink(prospectId, os) {
  return new Promise((resolve, reject) => {
    const isBranchReady = window?.branch;

    const linkData = {
      tags: ['web-application', os],
      data: {
        $deeplink_path: `nuapp://unauthenticated_flows/start_screen?origin=web&variant=variant-a&prospect_id=${prospectId}`,
        $desktop_url: DEFAULT_ANDROID_LINK,
      },
    };

    if (!isBranchReady) {
      reject(new Error('Branch not initialized'));
    }

    window?.branch?.link(linkData, (err, link) => {
      if (!err) {
        resolve(link);
      } else {
        reject(err);
      }
    });
  });
}
