import React from 'react';
import PropTypes from 'prop-types';

import { CREDIT_CARD } from 'utils/prospectTypes';
import { useApplicationFormDrawerContext } from 'patterns/ApplicationFormDrawer/ApplicationFormContext';

import { StyledImage } from './styles/Image';

const FormImages = ({ className }) => {
  const { prospectTypeKey } = useApplicationFormDrawerContext();
  const getImageProps = prospectTypeKey === CREDIT_CARD
    ? {
      altIntlKey: 'SHORT_FORM.IMAGE_ALT.CARDS',
      srcSet: {
        xs: {
          '1x': 'short-form-vertical/cards@1x.png',
          '2x': 'short-form-vertical/cards@2x.png',
          '3x': 'short-form-vertical/cards@3x.png',
        },
      },
    }
    : {
      altIntlKey: 'SHORT_FORM.IMAGE_ALT.PHONE_AND_CARD',
      srcSet: {
        xs: {
          '1x': 'short-form-vertical/phone-and-card@1x.png',
          '2x': 'short-form-vertical/phone-and-card@2x.png',
          '3x': 'short-form-vertical/phone-and-card@3x.png',
        },
      },
    };

  return <StyledImage className={className} {...getImageProps} />;
};

FormImages.defaultProps = {
  className: undefined,
};

FormImages.propTypes = {
  className: PropTypes.string,
};

export default FormImages;
