const SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;

export const get = () => new Promise(((resolve, reject) => {
  // eslint-disable-next-line no-undef
  try {
    const reCaptchaV3 = window.grecaptcha;
    return reCaptchaV3.ready(() => reCaptchaV3.execute(SITE_KEY, { action: 'submit' }).then(resolve));
  } catch (error) {
    return reject(error);
  }
}));
